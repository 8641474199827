
$fontPath:'~src/assets/fonts/';

@mixin fontSrc($name){
  src:url(#{$fontPath}#{$name}.woff) format('woff')
}


@font-face {
    font-family: Roboto;
    @include fontSrc(google/Roboto/Roboto-Medium);
    // src: url(assets/fonts/google/Roboto/Roboto-Medium.woff);
  }
  
  @font-face {
    font-family: Pacifico;
    @include fontSrc(google/Pacifico/Pacifico-Regular); 
    // src: url(assets/fonts/google/Pacifico/Pacifico-Regular.woff);
  }
  
  @font-face {
    font-family: Parisienne;
    @include fontSrc(google/Parisienne/Parisienne-Regular);
    // src: url(assets/fonts/google/Parisienne/Parisienne-Regular.woff);
  }
  
  @font-face {
      font-family: FangZhengSongTi;
      @include fontSrc(FangZhengShuSong-GBK-1);
      // src        : url(#{$fontPath}FangZhengShuSong-GBK-1.woff) format("woff");
    }
    
   
    @font-face {
      font-family: FangZhengKaiTi;
      @include fontSrc(FangZhengKaiTi-GBK-1);
      // src        : url(assets/fonts/FangZhengKaiTi-GBK-1.woff) format("woff");
    }
    
    
    
    @font-face {
      font-family: FangZhengHeiti;
      @include fontSrc(FangZhengHeiTi-GBK-1);
      // src        : url(assets/fonts/FangZhengHeiTi-GBK-1.woff) format("woff");
    }
   
    
    @font-face {
      font-family: FangZhengFangSong;
      @include fontSrc(FangZhengFangSong-GBK-1);
      // src        : url(assets/fonts/FangZhengFangSong-GBK-1.woff) format("woff");
    }
    
  
  // @font-face {
  //   font-family: HeitiFanTi;
  //   src        : url(assets/fonts/FangZhengHeiTiFanTi-1.ttf) format("truetype");
  // }
    