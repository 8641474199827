
.panel1 {
    padding: 0 !important;
    // background-color: transparent !important;
    height: 15vh !important;
    max-height: 120px;
    max-width: 100vw;
    width: 100vw !important;
    }
    
    
    .panel2 {
      padding: 0 !important;
      // background-color: transparent !important;
      height: 25vh !important;
      min-height: 90px;
      width: 100vw !important;
    }
    
    .panel3 {
      padding: 0 !important;
      // background-color: transparent !important;
      height: 35vh !important;
      min-height: 90px;
      width: 100vw !important;
    }