.shareDialogPanel{
    // background: transparent !important;
    // background:green !important;
    background-image: var(--backGroundImg) !important;
    background-size: cover;
    // border: solid red;
    .mat-dialog-container{
      // height: 300px;
      background: transparent !important;
      // padding: 0 24px;
    }
  
  }
  